var service = {};

if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://blackbox.teamfeeltech.com';
} else {
    service.API_URL = 'http://192.168.29.102:5000';
}
// service.API_URL = 'https://blackbox.teamfeeltech.com';

export default service;
